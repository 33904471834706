<template>
  <div id="graph">
    <transition-group name="fade">
      <div
        key="1"
        v-if="!this.countData || !this.visitsFilteredPatientStatus.length"
      >
        Seems there is no data that meets your criteria.
      </div>

      <div key="3" v-else>
        <div
          class="grid"
          v-responsive="{
            'grid-cols-1': (el) => el.width <= 800,
            'grid-cols-2': (el) => el.width > 800,
            'gap-16': (el) => el.width > 800,
            'grid-flow-row': (el) => el.width > 800
          }"
        >
          <div>
            <div v-if="this.countData && this.countData.length > 0">
              <h3 :class="chartHeaderClasses">
                Patient Status Ratio <small class="fs-12">(Encounters)</small>
              </h3>
              <status-chart
                class="status-profile-chart"
                :chartData="patientStatusDataStucture"
                :chartOptions="patientStatusChartOptions"
              ></status-chart>
            </div>
          </div>

          <!-- Don't show unless there a comparison to see -->
          <div v-if="genderData[0] > 0 && genderData[1] > 0">
            <h3 :class="chartHeaderClasses">
              Gender Ratio <small class="fs-12">(Encounters)</small>
            </h3>
            <div class="grid grid-cols-2 items-start">
              <gender-chart
                class="gender-profile-chart"
                :key="chartgenderKey"
                :chartData="genderDataStructure"
                :chartOptions="genderRatioChartOptions"
              ></gender-chart>
              <gender-insights :genderData="genderData"></gender-insights>
            </div>
          </div>
          <!-- prettier-ignore -->
          <div
            v-if="
              ageRangeData &&
              this.countData &&
              this.countData.length > 0"
              class="col-span-full"
              >
            <h3 :class="chartHeaderClasses">Encounters by Age Group and Gender</h3>
            <age-chart
              class="age-by-gender-profile-chart"
              :key="chartageKey"
              :chartData="ageDataStructure"
              :chartOptions="ageDataChartOptions"
            ></age-chart>
          </div>

          <div
            v-if="
              ageRangeLos &&
              countData &&
              countData.length > 0 &&
              patientStatusEqualsInpatient
            "
            class="col-span-full"
          >
            <h3 :class="chartHeaderClasses">
              Average <abbr title="Length of Stay">LoS</abbr> by Age Range
            </h3>
            <line-chart
              class="age-by-gender-profile-chart"
              :key="4"
              :chartData="ageRangeLosDataStructure"
              :chartOptions="ageDataLosChartOptions"
            ></line-chart>
          </div>

          <div v-if="ageInsights" data-age-insights-test>
            <h3 :class="chartHeaderClasses">Age Insights</h3>
            <insights-table :insights="ageInsights"></insights-table>
          </div>
          <div
            v-if="losInsights && patientStatusEqualsInpatient"
            data-los-insights-test
          >
            <h3 :class="chartHeaderClasses">Length of Stay Insights</h3>
            <insights-table :insights="losInsights"></insights-table>
          </div>
          <!--<div
            v-if="drugCostPerDayInsights && patientStatusEqualsInpatient"
            data-dcpd-insights-test
          >
            <h3 :class="chartHeaderClasses">Drug Cost Per Day Insights</h3>
            <length-of-stay-insights-table :insights="drugCostPerDayInsights"></length-of-stay-insights-table>
          </div> -->
          <!-- <div v-if="drugCostPerEncounterInsights" data-dcpe-insights-test>
            <h3 :class="chartHeaderClasses">
              Drug Cost Per Encounter Insights
            </h3>
            <length-of-stay-insights-table
              :insights="drugCostPerEncounterInsights"
            ></length-of-stay-insights-table>
          </div> -->
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
  import maxBy from 'lodash/maxBy';
  import minBy from 'lodash/minBy';
  import { formatNumber } from '@/js/utilities';

  import {
    calculatePatientStatusWithReadmissionDataVisits,
    weightedAvgDrugCostPerDay,
    weightedAvgDrugCostPerEncounter,
    weightedAvgLos,
    weightedAvgAge
  } from '@/data';

  // split and lazy load components
  const statusChart = () => import('@/components/charts/HorizontalBar');
  const genderChart = () => import('../charts/Pie');
  const ageChart = () => import('../charts/VerticalBar');
  const LineChart = () => import('../charts/Line');
  const genderInsights = () => import('../popbuilder/GenderInsights_v2');
  const InsightsTable = () => import('../popbuilder/LengthOfStayInsightsTable.vue');

  export default {
    name: 'PopBuilderBuildCharts',
    components: {
      statusChart,
      genderChart,
      ageChart,
      genderInsights,
      InsightsTable,
      LineChart
    },
    props: {
      filterPatientStatus: Array,
      ageRangeChoices: Array,
      genderChoices: Array,
      countData: Array,
      genderData: Array,
      ageRangeData: Object,
      ageRangeReadmissions: [Object, Boolean],
      ageRangeAveAge: Object,
      visitsFilteredPatientStatus: Array,
      ageRangeLos: Object,
      ageRangeDataTotals: Object
    },
    computed: {
      genderDataStructure() {
        return {
          datasets: [
            {
              weight: 0.2,
              data: this.genderData,
              backgroundColor: [
                this.crcaColors.dataVis5,
                this.crcaColors.dataVis14
              ],
              borderColor: this.crcaColors.bodyBackground,
              borderWidth: 2,
              label: 'Encounters'
            }
          ],

          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels: ['Male', 'Female'],
          options: {
            cutoutPercentage: 80
          }
        };
      },
      patientStatusIncludesInpatient() {
        return this.filterPatientStatus.includes('inpatient');
      },
      patientStatusIncludesOutpatient() {
        return this.filterPatientStatus.includes('outpatient');
      },
      patientStatusBoth() {
        return (
          this.patientStatusIncludesInpatient &&
          this.patientStatusIncludesOutpatient
        );
      },
      patientStatusEqualsInpatient() {
        return (
          this.patientStatusIncludesInpatient &&
          !this.patientStatusIncludesOutpatient
        );
      },
      patientStatusEqualsOutpatient() {
        return (
          !this.patientStatusIncludesInpatient &&
          this.patientStatusIncludesOutpatient
        );
      },
      // ToDo: move data things up a level
      patientStatusData() {
        return calculatePatientStatusWithReadmissionDataVisits(this.countData);
      },
      patientStatusDataStucture() {
        let labels = [];
        let data = [];
        let datasets = [];
        const inpatientNoReadmissions =
          this.patientStatusData.inpatient.totalCount -
          this.patientStatusData.inpatient.readmissionCount;
        if (this.patientStatusEqualsInpatient) {
          labels = [['Inpatient', 'Encounters']];
          data = [inpatientNoReadmissions];
          datasets = [
            {
              label: 'Non-Readmissions',
              data: data,
              backgroundColor: [
                this.crcaColors.dataVis5,
                this.crcaColors.dataVis7
              ]
            },
            {
              label: 'Readmissions',
              data: [this.patientStatusData.inpatient.readmissionCount],
              backgroundColor: [this.crcaColors.dataVis9]
            }
          ];
        } else if (this.patientStatusEqualsOutpatient) {
          labels = [['Outpatient', 'Encounters']];
          data = [this.patientStatusData.outpatient.totalCount];
          datasets = [
            {
              label: 'Non-Readmissions',
              data: data,
              backgroundColor: [this.crcaColors.dataVis7]
            }
          ];
        } else if (this.patientStatusBoth) {
          labels = [
            ['Inpatient', 'Encounters'],
            ['Outpatient', 'Encounters']
          ];
          data = [
            inpatientNoReadmissions,
            this.patientStatusData.outpatient.totalCount
          ];
          datasets = [
            {
              label: 'Non-Readmissions',
              data: data,
              backgroundColor: [
                this.crcaColors.dataVis5, // inpatient non-re
                this.crcaColors.dataVis7 // outpatient
              ]
            },
            {
              label: 'Readmissions',
              data: [this.patientStatusData.inpatient.readmissionCount],
              backgroundColor: [this.crcaColors.dataVis9]
            }
          ];
        }

        return {
          labels: labels,
          datasets: datasets
        };
      },
      filteredTotalsAgeRangeData() {
        return Object.values(this.ageRangeDataTotals).filter((d, i) =>
          this.ageRangeChoices.includes(i.toString())
        );
      },
      filteredMaleData() {
        return Object.values(this.ageRangeData.male).filter((d, i) =>
          this.ageRangeChoices.includes(i.toString())
        );
      },
      filteredFemaleData() {
        return Object.values(this.ageRangeData.female).filter((d, i) =>
          this.ageRangeChoices.includes(i.toString())
        );
      },
      filteredAgeRangeReadmissionsData() {

        // rate
        const remissions = Object.values(this.ageRangeReadmissions).filter(
          (d, i) => this.ageRangeChoices.includes(i.toString())
        );

        return remissions.map((d, i) =>
          // if we do this for real, I need to get the number with unknown, but this gives a visual aproximation
          formatNumber((d / this.filteredTotalsAgeRangeData[i]) * 100, {
            format: 'precision',
            places: 1
          })
        );
      },
      filteredAgeRangeLosData() {
        const ages = Object.values(this.ageRangeLos).filter((d, i) =>
          this.ageRangeChoices.includes(i.toString())
        );
        return ages.map((age) => (age ? Math.round(age) : age));
      },
      filteredAgeLables() {
        const labels = [
          'Unknown',
          '0-17',
          '18-24',
          '25-34',
          '35-44',
          '45-54',
          '55-64',
          '65-74',
          '75-84',
          '85+'
        ];

        return labels.filter((d, i) =>
          this.ageRangeChoices.includes(i.toString())
        );
      },
      ageDataStructure() {
        let datasets = [];
        if (this.ageRangeReadmissions) {
          datasets.push({
            type: 'line',
            label: 'Readmissions Rate',
            yAxisID: 'rate',
            fill: false,
            borderColor: this.crcaColors.dataVis9,
            data: this.filteredAgeRangeReadmissionsData
          });
        }
        if (this.genderChoices.includes('1')) {
          datasets.push({
            label: 'Male',
            yAxisID: 'visits',
            stack: 'allGenders',
            backgroundColor: this.crcaColors.dataVis5,
            data: this.filteredMaleData
          });
        }
        if (this.genderChoices.includes('2')) {
          datasets.push({
            label: 'Female',
            yAxisID: 'visits',
            stack: 'allGenders',
            backgroundColor: this.crcaColors.dataVis14,
            data: this.filteredFemaleData
          });
        }
        return {
          datasets: datasets,
          labels: this.filteredAgeLables
        };
      },
      ageRangeLosDataStructure() {
        let datasets = [
          {
            label: 'Average Length of Stay',
            fill: false,
            borderColor: this.crcaColors.dataVis3,
            data: this.filteredAgeRangeLosData,
            yAxisID: 'days'
          },
          {
            label: 'Readmissions Rate',
            fill: false,
            borderColor: this.crcaColors.dataVis9,
            data: this.filteredAgeRangeReadmissionsData,
            yAxisID: 'visits'
          }
        ];
        return {
          datasets: datasets,
          labels: this.filteredAgeLables
        };
      },
      ageInsights() {
        if (this.countData && this.countData.length > 0) {
          let visitData = this.visitsFilteredPatientStatus;

          if (this.patientStatusBoth) {
            visitData = this.countData;
          }
          return [
            {
              label: 'Average',
              value: Math.round(weightedAvgAge(visitData))
            },
            {
              label: 'Max',
              value: maxBy(visitData, 'maxAge').maxAge
            },
            {
              label: 'Min',
              value: minBy(visitData, 'minAge').minAge
            }
          ];
        }
        return null;
      },
      drugCostPerDayInsights() {
        if (this.countData && this.countData.length > 0) {
          let visitData = this.visitsFilteredPatientStatus;
          if (this.patientStatusBoth) {
            visitData = this.countData;
          }
          return [
            {
              label: 'Average',
              value: weightedAvgDrugCostPerDay(visitData),
              format: 'dollarLargeAbbr'
            },
            {
              label: 'Max',
              value: maxBy(visitData, 'maxDrugCostPerDay').maxDrugCostPerDay,
              format: 'dollarLargeAbbr'
            },
            {
              label: 'Min',
              value: minBy(visitData, 'minDrugCostPerDay').minDrugCostPerDay,
              format: 'dollarLargeAbbr'
            }
          ];
        }
        return null;
      },
      losInsights() {
        if (this.countData && this.countData.length > 0) {
          let visitData = this.visitsFilteredPatientStatus;
          if (this.patientStatusBoth) {
            visitData = this.countData;
          }
          return [
            {
              label: 'Average',
              value: formatNumber(weightedAvgLos(visitData), {
                format: 'precision',
                places: 1
              })
            },
            {
              label: 'Max',
              value: maxBy(visitData, 'maxLos').maxLos
            },
            {
              label: 'Min',
              value: minBy(visitData, 'minLos').minLos
            }
          ];
        }
        return null;
      },
      ageDataLosChartOptions() {
        return {
          tooltips: {
            enabled: true,
            custom: false, // new custom tooltips for horizontal bars need a little more work to look correct on this chart
            callbacks: {
              title: function (tooltipItem, data) {
                return tooltipItem[0].label;
              },
              label: function (tooltipItem, data) {
                const val =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];

                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  ': ' +
                  formatNumber(val, { format: 'commas' })
                );
              }
            }
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  fontColor: this.crcaColors.dataVis3,
                  display: true,
                  labelString: 'Days Stayed'
                },
                id: 'days',
                ticks: {
                  suggestedMax: 10,
                  suggestedMin: 10,
                  stepSize: 1,
                  callback: function (value) {
                    return formatNumber(value, {
                      format: 'largeNumberAbbr',
                      places: 0
                    });
                  }
                }
              },
              {
                scaleLabel: {
                  fontColor: this.crcaColors.dataVis9,
                  display: true,
                  labelString: 'Rate of Readmission'
                },
                id: 'visits',
                position: 'right',
                display: true,
                ticks: {
                  max: 100,
                  callback: function (value) {
                    return formatNumber(value, {
                      format: 'largeNumberAbbr',
                      places: 0
                    });
                  }
                }
              }
            ]
          }
        };
      }
    },
    data() {
      return {
        chartgenderKey: 1,
        chartageKey: 2,
        watchers: {
          unwatchPopData: null
        },
        chartHeaderClasses: 'rfs-24 rfs-m-2-t rfs-m-2-b',
        patientStatusChartOptions: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                ticks: {
                  callback: function (value) {
                    return formatNumber(value, {
                      format: 'largeNumberAbbr',
                      places: 0
                    });
                  }
                }
              }
            ],
            yAxes: [
              {
                stacked: true
              }
            ]
          },
          tooltips: {
            enabled: true,
            custom: false, // new custom tooltips for horizontal bars need a little more work to look correct on this chart
            callbacks: {
              title: function (tooltipItem) {
                return tooltipItem[0].yLabel.join(' ');
              },
              afterFooter: function (tooltipItem) {
                if (tooltipItem[1]) {
                  return (
                    'Readmissions Rate: ' +
                    formatNumber(
                      (tooltipItem[1].xLabel /
                        (tooltipItem[0].xLabel + tooltipItem[1].xLabel)) *
                        100,
                      {
                        format: 'precision',
                        places: 1
                      }
                    ) +
                    '%'
                  );
                }
                return null;
              },
              label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label;
                var val = 0;

                // Replacing Non-Readmissions with Total
                if (label == 'Non-Readmissions') {
                  label = 'Total Count';
                  val = 0;

                  for (let i in data.datasets) {
                    if (data.datasets[i].data[tooltipItem.index]) {
                      val += data.datasets[i].data[tooltipItem.index];
                    }
                  }
                } else {
                  val =
                    data.datasets[tooltipItem.datasetIndex].data[
                      tooltipItem.index
                    ];
                }

                return label + ': ' + formatNumber(val, { format: 'commas' });
              }
            }
          }
        },
        genderRatioChartOptions: {
          tooltips: {
            enabled: true,
            custom: false, // new custom tooltips for horizontal bars need a little more work to look correct on this chart
            callbacks: {
              title: function (tooltipItem, data) {
                return data.datasets[0].label;
              },
              label: function (tooltipItem, data) {
                const val =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];

                return (
                  data.labels[tooltipItem.index] +
                  ': ' +
                  formatNumber(val, { format: 'commas' })
                );
              }
            }
          }
        },
        ageDataChartOptions: {
          tooltips: {
            enabled: true,
            custom: false, // new custom tooltips for horizontal bars need a little more work to look correct on this chart
            callbacks: {
              title: function (tooltipItem, data) {
                return tooltipItem[0].label;
              },
              label: function (tooltipItem, data) {
                const val =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];

                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  ': ' +
                  formatNumber(val, { format: 'commas' })
                );
              }
            }
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: false,
                  labelString: 'Number of Encounters'
                },
                id: 'visits',
                stacked: true,
                ticks: {
                  min: 0,
                  suggestedMax: 5,
                  precision: 0,
                  callback: function (value) {
                    return formatNumber(value, {
                      format: 'largeNumberAbbr',
                      places: 0
                    });
                  }
                }
              },
              {
                scaleLabel: {
                  display: false,
                  labelString: 'Rate of Readmission'
                },
                display: false,
                position: 'right',
                id: 'rate',
                ticks: {
                  max: 75,
                  callback: function (value) {
                    return (
                      formatNumber(value, { format: 'largeNumberAbbr' }) + '%'
                    );
                  }
                }
              }
            ]
          }
        }
      };
    },
    mounted() {},
    beforeDestroy() {}
  };
</script>
